/*
                        _   _       _a_a
            _   _     _{.`=`.}_    {/ ''\_
      _    {.`'`.}   {.'  _  '.}  {|  ._oo)
     { \  {/ .-. \} {/  .' '.  \} {/  |
~^~^~`~^~`~^~`~^~`~^~^~`^~^~`^~^~^~^~^~^~`^~^~`^~^~`^~^~`^~^~`^~^~`^~^~`^~^~`^~
/* ----------------------------------------------------------------------------
[Master Stylesheet]

Project:        Promogroup
Version:        1.0
Last change:    22/02/2017
Created by:     Yves Engetschwiler (info@bee-interactive.ch)
// --------------------------------------------------------------------------*/
.bx-wrapper {
  position: relative;
  margin: 0 auto 0;
  padding: 0;
  *zoom: 1;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
.bx-wrapper img {
  max-width: 100%;
  width: 100%;
  display: block;
}
.bxslider {
  margin: 0;
  padding: 0;
}
ul.bxslider {
  list-style: none;
}
.bx-viewport {
/*fix other elements on the page moving (on Chrome)*/
  -webkit-transform: translatez(0);
}
.bx-wrapper {
  background: #eae6e3;
}
.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
  position: absolute;
  bottom: -30px;
  width: 100%;
}
.bx-wrapper .bx-loading {
  min-height: 50px;
  background: center center no-repeat #eae6e3;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
}
.bx-wrapper .bx-pager {
  text-align: center;
  font-size: 0.85em;
  font-family: Arial;
  font-weight: bold;
  color: #666;
  padding-top: 20px;
}
.bx-wrapper .bx-pager.bx-default-pager a {
  background: #666;
  text-indent: -9999px;
  display: block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  outline: 0;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active,
.bx-wrapper .bx-pager.bx-default-pager a:focus {
  background: #000;
}
.bx-wrapper .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
  display: inline-block;
  *zoom: 1;
  *display: inline;
}
.bx-wrapper .bx-pager-item {
  font-size: 0;
  line-height: 0;
}
.bx-wrapper .bx-prev {
  left: 10px;
}
.bx-wrapper .bx-prev:hover,
.bx-wrapper .bx-prev:focus {
  background-position: 0 0;
}
.bx-wrapper .bx-next {
  right: 10px;
}
.bx-wrapper .bx-next:hover,
.bx-wrapper .bx-next:focus {
  background-position: -43px 0;
}
.bx-wrapper .bx-controls-direction a {
  position: absolute;
  top: 50%;
  margin-top: -16px;
  outline: 0;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  z-index: 9999;
}
.bx-wrapper .bx-controls-direction a.disabled {
  display: none;
}
.bx-wrapper .bx-controls-auto {
  text-align: center;
}
.bx-wrapper .bx-controls-auto .bx-start {
  display: block;
  text-indent: -9999px;
  width: 10px;
  height: 11px;
  outline: 0;
  margin: 0 3px;
}
.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active,
.bx-wrapper .bx-controls-auto .bx-start:focus {
  background-position: -86px 0;
}
.bx-wrapper .bx-controls-auto .bx-stop {
  display: block;
  text-indent: -9999px;
  width: 9px;
  height: 11px;
  outline: 0;
  margin: 0 3px;
}
.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active,
.bx-wrapper .bx-controls-auto .bx-stop:focus {
  background-position: -86px -33px;
}
.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
  text-align: left;
  width: 80%;
}
.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
  right: 0;
  width: 35px;
}
.bx-wrapper .bx-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #666;
  background: rgba(80,80,80,0.75);
  width: 100%;
}
.bx-wrapper .bx-caption span {
  color: #fff;
  font-family: Arial;
  display: block;
  font-size: 0.85em;
  padding: 10px;
}
@media only screen and (min-width: 400px) and (max-width: 1050px) and (orientation: portrait) {
  .container {
    width: 100%;
  }
}
.section-title {
  font-family: TimesIFA-Regular;
  font-size: 36px;
  color: #075a72;
  letter-spacing: 0;
}
@media only screen and (max-width: 400px) {
  .section-title {
    font-size: 24px;
  }
}
.section-introduction {
  font-family: HelveticaNeue-Light;
  font-size: 17px;
  color: #6b6b6b;
  letter-spacing: 0;
  line-height: 22px;
}
.header {
  background: #003848;
  background: -moz-linear-gradient(left, #003848 0%, #00576f 50%, #41738c 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, #003848), color-stop(50%, #00576f), color-stop(100%, #41738c));
  background: -webkit-linear-gradient(left, #003848 0%, #00576f 50%, #41738c 100%);
  background: -o-linear-gradient(left, #003848 0%, #00576f 50%, #41738c 100%);
  background: -ms-linear-gradient(left, #003848 0%, #00576f 50%, #41738c 100%);
  background: linear-gradient(to right, #003848 0%, #00576f 50%, #41738c 100%);
}
.bordered {
  border: solid 1px #f9b600;
  padding: 13px 15px;
  color: #f9b600;
}
.bordered:hover {
  color: #fff;
  border-color: #fff;
}
.navbar {
  margin-bottom: 0;
}
.header.sticky_header .navbar-nav li {
  padding: 53px 0;
  transition: padding 0.25s ease-out;
}
@media only screen and (max-width: 400px) {
  .header.sticky_header .navbar-nav li {
    padding: 0 20px;
    margin: 5px 0;
    display: block;
    width: 100%;
  }
}
.header.sticky_header .navbar-nav li a {
  padding: 13px 15px;
}
.header.sticky_header .navbar-nav li a.bordered {
  color: #f9b600;
}
.header.sticky_header .navbar-nav li a.bordered:hover {
  color: #fff;
}
@media only screen and (min-width: 400px) and (max-width: 1050px) and (orientation: portrait) {
  .header.sticky_header .navbar-nav li a {
    padding: 13px 3px;
  }
}
@media only screen and (max-width: 400px) {
  .navbar-header {
    height: 70px;
  }
}
@media only screen and (max-width: 400px) {
  .header .logo,
  .header.sticky_header .logo {
    padding-top: 13px;
  }
}
.navbar-brand.logo img {
  transition: height 0.25s ease-out;
}
@media only screen and (max-width: 400px) {
  .navbar-brand.logo {
    padding-top: 13px;
  }
  .navbar-brand.logo img {
    height: 45px !important;
    width: 45px;
    float: left;
    text-align: left;
  }
}
.mobile_menu_btn {
  border: transparent;
  margin-top: 15px;
}
.navbar-toggle .icon-bar {
  width: 35px;
  height: 3px;
  border-radius: 1px;
}
.navbar-toggle .icon-bar+.icon-bar {
  margin-top: 5px;
}
.opened .navbar-brand {
  padding: 14px 15px !important;
}
@media only screen and (max-width: 400px) {
  .opened .navbar-brand {
    padding: 14px 15px !important;
  }
}
.opened .navbar-brand img {
  height: 60px;
}
@media only screen and (max-width: 400px) {
  .opened .navbar-brand img {
    height: 45px;
  }
}
.opened .navbar-nav li {
  padding: 20px 0 !important;
}
@media only screen and (max-width: 400px) {
  .opened .navbar-nav li {
    display: block;
    width: 100%;
    margin: 5px 0 !important;
    padding: 0 20px !important;
  }
}
.slider {
  padding-top: 125px;
  background: #eae6e3;
}
@media only screen and (max-width: 400px) {
  .slider {
    padding-top: 45px;
  }
}
.inner-content {
  padding-right: 110px;
  padding-left: 110px;
}
@media only screen and (max-width: 400px) {
  .inner-content {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (min-width: 400px) and (max-width: 1050px) and (orientation: portrait) {
  .inner-content {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.ethique {
  padding-top: 60px;
  background: #eae6e3;
}
@media only screen and (max-width: 400px) {
  .ethique {
    padding-top: 0px;
  }
}
.ethique.padding-top-30 {
  padding-top: 30px;
}
.expertise {
  background: #f4f2f1;
  padding-top: 60px;
  padding-bottom: 60px;
}
@media only screen and (max-width: 400px) {
  .expertise {
    padding-top: 30px;
  }
}
.expertise .expertise-wrap h3 {
  font-family: TimesIFA-Regular;
  font-size: 36px;
  letter-spacing: 0;
}
.expertise .expertise-wrap p {
  font-family: SuisseBPIntl-Light;
  font-size: 18px;
  color: #00576f;
  letter-spacing: 0;
}
.expertise .expertise-wrap .img-expertise {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  background: #f8f6f6;
  white-space: nowrap;
  margin: 0 auto;
}
.expertise .expertise-wrap .img-expertise .helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.expertise .expertise-wrap .img-expertise .helper img {
  vertical-align: middle;
}
.expertise .expertise-wrap.blue h3,
.expertise .expertise-wrap.blue p {
  color: #075a72;
}
.expertise .expertise-wrap.blue .action-button {
  background: #075a72 !important;
}
.expertise .expertise-wrap.red h3,
.expertise .expertise-wrap.red p {
  color: #d35458;
}
.expertise .expertise-wrap.red .action-button {
  background: #d35458 !important;
}
.expertise .expertise-wrap.green h3,
.expertise .expertise-wrap.green p {
  color: #719944;
}
.expertise .expertise-wrap.green .action-button {
  background: #719944 !important;
}
.quisommesnous {
  background: #eae6e3;
  padding-top: 60px;
}
@media only screen and (max-width: 400px) {
  .quisommesnous {
    padding-top: 30px;
  }
}
.contact {
  background: #eae6e3;
  padding-top: 60px;
}
@media only screen and (max-width: 400px) {
  .contact {
    padding-top: 30px;
  }
}
.contact .bubble-wrap {
  margin-bottom: 44px;
}
.contact #map {
  height: 385px;
  margin-top: 100px;
}
.contact p {
  font-family: SuisseBPIntl-Light;
  font-size: 18px;
  line-height: 20px;
  color: #6b6b6b;
  letter-spacing: 0;
}
.ifa-tooltip {
  display: inline-block;
  position: relative;
  text-decoration: none;
  min-width: 167px;
  height: 65px;
}
.ifa-tooltip:after {
  font-family: TimesIFA-Regular;
  font-size: 18px;
  color: #f9b600;
  letter-spacing: 1.83px;
  background: #075a72;
  background: #075a72;
  content: attr(data-title);
  padding: 11px 22px;
  white-space: nowrap;
  display: block;
  position: absolute;
  left: 0;
  bottom: 18px;
  z-index: 8;
}
.ifa-tooltip:before {
  border: solid;
  border-color: #075a72 transparent;
  border-width: 15px 15px 0 15px;
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  bottom: 3px;
  margin-left: -15px;
  z-index: 9;
}
.button {
  display: inline-block;
  position: relative;
  text-decoration: none;
  min-width: 167px;
  font-family: TimesIFA-Regular;
  font-size: 18px;
  color: #f9b600;
  letter-spacing: 1.83px;
  background: #075a72;
  background: #075a72;
  padding: 11px 30px;
  z-index: 8;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1), 6px 8px 9px 0 rgba(0,0,0,0.15);
}
@media only screen and (max-width: 400px) {
  .button {
    min-width: 240px;
  }
}
.button.phone {
  background: #075a72 url("/img/phone.svg") no-repeat 20px center;
  background-size: 19px;
  padding-left: 50px;
}
.button:hover {
  color: #fff;
  box-shadow: 0 6px 6px 0 rgba(0,0,0,0.2), 6px 8px 9px 0 rgba(0,0,0,0.25);
}
.separator {
  width: 100%;
  height: 2px;
  background: rgba(7,90,114,0.3);
  border-bottom: solid 1px #fff;
  margin: 30px 0 50px 0;
}
.action-button {
  border-radius: 50%;
  background: #075a72;
  box-shadow: 2px 4px 6px 0 rgba(0,0,0,0.23);
  transition: background 0.25s ease-out;
  transition: box-shadow 0.25s ease-out;
}
.action-button:hover {
  background: #065167;
  box-shadow: 0 6px 6px 0 rgba(0,0,0,0.16), 6px 6px 9px 0 rgba(0,0,0,0.18);
}
.bubble-with-action-button {
  position: relative;
}
.bubble-with-action-button .button-beside-bubble {
  position: absolute;
  top: 0;
  margin-left: 340px;
  cursor: default;
}
.responsive-video {
  height: 0px;
  margin: 30px 0;
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  width: 100%;
}
.responsive-video iframe {
  height: 100%;
  left: 0px;
  position: absolute;
  z-index: 998;
  top: 0px;
  width: 100%;
}
.form-horizontal {
  margin-top: 40px;
}
.form-horizontal .control-label {
  text-align: left;
}
.form-horizontal textarea {
  resize: none;
  font-family: TimesIFA-Regular;
}
.form-horizontal .control-label {
  font-weight: lighter;
  font-family: TimesIFA-Regular;
}
.form-horizontal .right-arrow-bordered {
  font-family: TimesIFA-Regular;
  font-size: 18px;
  color: #f9b600;
  letter-spacing: 1.52px;
  background: #075a72;
  border: none;
  box-shadow: 6px 8px 9px 0 rgba(0,0,0,0.15);
  padding-left: 65px;
  padding-right: 65px;
  margin-bottom: 114px;
}
.form-horizontal .right-arrow-bordered:hover {
  color: #fff;
  box-shadow: 0 6px 6px 0 rgba(0,0,0,0.2), 6px 8px 9px 0 rgba(0,0,0,0.25);
}
.form-horizontal .form-control {
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #fff;
  font-weight: normal;
  font-family: TimesIFA-Regular;
}
.form-horizontal .form-control:active,
.form-horizontal .form-control:focus {
  border-color: #3f738d;
}
.alert {
  border-radius: 0 !important;
}
.toggle {
  cursor: pointer;
}
.content-hidden {
  display: none;
}
.zoomable {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}
.transition {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
.align-center {
  text-align: center;
}
@media only screen and (max-width: 400px) {
  .align-center-mobile {
    text-align: center !important;
  }
}
@media only screen and (max-width: 400px) {
  .margin-bottom-30-mobile {
    margin-bottom: 30px !important;
  }
}
.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.margin-top-30 {
  margin-top: 30px;
}
.margin-bottom-40 {
  margin-bottom: 40px;
}
.margin-bottom-60 {
  margin-bottom: 60px;
}
.margin-bottom-80 {
  margin-bottom: 80px;
}
.margin-top-30 {
  margin-top: 30px;
}
.min-height-70 {
  min-height: 70px;
}
@media only screen and (max-width: 400px) {
  .min-height-70 {
    min-height: auto;
  }
}
.no-margin-bottom {
  margin-bottom: 0;
}
.spacer-30 {
  height: 1px;
  width: 100%;
  margin-top: 30px;
}
.no-padding-left {
  padding-left: 0;
}
@media only screen and (max-width: 400px) {
  .no-padding-left {
    padding-left: 15px;
  }
}
.no-padding-right {
  padding-right: 0;
}
@media only screen and (max-width: 400px) {
  .no-padding-right {
    padding-right: 15px;
  }
}
@media only screen and (max-width: 400px) {
  .padding-left-mobile-0 {
    padding-left: 0 !important;
  }
}
@media only screen and (max-width: 400px) {
  .padding-right-mobile-0 {
    padding-right: 0 !important;
  }
}
.right-arrow-bordered {
  border: 1px solid #8cabbb;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  position: relative;
  background: #075a72;
  height: 47px;
  float: right;
  webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.right-arrow-bordered a {
  display: inline-block;
  line-height: 44px;
  padding-left: 17px;
  padding-right: 17px;
  font-family: TimesIFA-Regular;
}
.right-arrow-bordered a:hover {
  color: #f9b600;
}
.padding-desktop-top-60 {
  padding-top: 60px !important;
}
@media only screen and (max-width: 400px) {
  .padding-desktop-top-60 {
    padding-top: 30px !important;
  }
}
@media only screen and (min-width: 400px) and (max-width: 1050px) {
  .padding-desktop-top-60 {
    padding-top: 30px !important;
  }
}
.with-100 {
  width: 100%;
  vertical-align: top;
}
.clearfix {
  clear: both;
}
.show-mobile {
  display: none !important;
}
@media only screen and (max-width: 400px) {
  .show-mobile {
    display: block !important;
  }
}
@media only screen and (max-width: 400px) {
  .hide-mobile {
    display: none !important;
  }
}
@media only screen and (min-width: 400px) and (max-width: 1050px) {
  .hide-tablet {
    display: none !important;
  }
}
