/*
                        _   _       _a_a
            _   _     _{.`=`.}_    {/ ''\_
      _    {.`'`.}   {.'  _  '.}  {|  ._oo)
     { \  {/ .-. \} {/  .' '.  \} {/  |
~^~^~`~^~`~^~`~^~`~^~^~`^~^~`^~^~^~^~^~^~`^~^~`^~^~`^~^~`^~^~`^~^~`^~^~`^~^~`^~
/* ----------------------------------------------------------------------------
[Master Stylesheet]

Project:        Promogroup
Version:        1.0
Last change:    22/02/2017
Created by:     Yves Engetschwiler (info@bee-interactive.ch)
// --------------------------------------------------------------------------*/


// Import styles
// --------------------------------------------------------------------------*/
@import('bxslider')

.container
    +tablet()
        +portrait()
            width: 100%

.section-title
    font-family: TimesIFA-Regular
    font-size: 36px
    color: #075a72
    letter-spacing: 0

    +mobile()
        font-size: 24px

.section-introduction
    font-family: HelveticaNeue-Light
    font-size: 17px
    color: #6B6B6B
    letter-spacing: 0
    line-height: 22px

.header
    background: rgba(0,56,72,1);
    background: -moz-linear-gradient(left, rgba(0,56,72,1) 0%, rgba(0,87,111,1) 50%, rgba(65,115,140,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,56,72,1)), color-stop(50%, rgba(0,87,111,1)), color-stop(100%, rgba(65,115,140,1)));
    background: -webkit-linear-gradient(left, rgba(0,56,72,1) 0%, rgba(0,87,111,1) 50%, rgba(65,115,140,1) 100%);
    background: -o-linear-gradient(left, rgba(0,56,72,1) 0%, rgba(0,87,111,1) 50%, rgba(65,115,140,1) 100%);
    background: -ms-linear-gradient(left, rgba(0,56,72,1) 0%, rgba(0,87,111,1) 50%, rgba(65,115,140,1) 100%);
    background: linear-gradient(to right, rgba(0,56,72,1) 0%, rgba(0,87,111,1) 50%, rgba(65,115,140,1) 100%);


.bordered
    border: solid 1px #f9b600
    padding: 13px 15px
    color: #f9b600
    
    &:hover
        color: #fff
        border-color: #fff

.navbar
    margin-bottom: 0

.header.sticky_header
    .navbar-nav
        li
            padding: 53px 0
            transition: padding 0.25s ease-out

            +mobile()
                padding: 0 20px
                margin: 5px 0
                display: block
                width: 100%

            a
                padding: 13px 15px
                
                +tablet()
                    +portrait()
                        padding: 13px 3px

                &.bordered
                    color: #f9b600
                    
                    &:hover
                        color: #fff

.navbar-header
    +mobile()
        height: 70px

.header .logo, .header.sticky_header .logo
    +mobile()
        padding-top: 13px

.navbar-brand
    &.logo
        img
            transition: height 0.25s ease-out

    +mobile()
        &.logo
            padding-top: 13px

            img
                height: 45px!important
                width: 45px
                float: left
                text-align: left

.mobile_menu_btn
    border: transparent
    margin-top: 15px

.navbar-toggle
    .icon-bar
        width: 35px
        height: 3px
        border-radius: 1px

.navbar-toggle
    .icon-bar+.icon-bar
        margin-top: 5px

// .navbar-header
//     +mobile()
//         height


.opened
    .navbar-brand
        padding: 14px 15px!important
        
        +mobile()
            padding: 14px 15px !important

        img
            height: 60px
            
            +mobile()
                height: 45px
    
    .navbar-nav
        li
            padding: 20px 0!important

            +mobile()
                display: block
                width: 100%
                margin: 5px 0 !important
                padding: 0 20px !important

.slider
    padding-top: 125px
    background: #eae6e3
    
    +mobile()
        padding-top: 45px


// Inner content
.inner-content
    padding-right: 110px
    padding-left: 110px
    
    +mobile()
        padding-left: 0
        padding-right: 0

    +tablet()
        +portrait()
            padding-left: 20px
            padding-right: 20px

// Ethique
.ethique
    padding-top: 60px
    background: #eae6e3

    +mobile()
        padding-top: 0px

    &.padding-top-30
        padding-top: 30px


// Domaines d'expertise
.expertise
    background: #f4f2f1
    padding-top: 60px
    padding-bottom: 60px

    +mobile()
        padding-top: 30px

    .expertise-wrap
        h3
            font-family: TimesIFA-Regular
            font-size: 36px
            letter-spacing: 0
        
        p
            font-family: SuisseBPIntl-Light
            font-size: 18px
            color: #00576F
            letter-spacing: 0

        .img-expertise
            border-radius: 50%
            width: 200px
            height: 200px
            background: #f8f6f6
            white-space: nowrap
            margin: 0 auto
            
            .helper
                display: inline-block
                height: 100%
                vertical-align: middle

                img
                    vertical-align: middle

        &.blue
            h3, p
                color: #075a72
            
            .action-button
                background: #075a72!important
        
        &.red
            h3, p
                color: #d35458
            
            .action-button
                background: #d35458!important
        
        &.green
            h3, p
                color: #719944
            
            .action-button
                background: #719944!important


// Qui sommes-nous
.quisommesnous
    background: #eae6e3
    padding-top: 60px

    +mobile()
        padding-top: 30px

// Qui sommes-nous
.contact
    background: #eae6e3
    padding-top: 60px

    +mobile()
        padding-top: 30px

    .bubble-wrap
        margin-bottom: 44px

    #map
        height: 385px
        margin-top: 100px
    
    p
        font-family: SuisseBPIntl-Light
        font-size: 18px
        line-height: 20px
        color: #6B6B6B
        letter-spacing: 0


// Tooltip
.ifa-tooltip
    display: inline-block
    position: relative
    text-decoration: none
    min-width: 167px
    height: 65px

.ifa-tooltip:after
    font-family: TimesIFA-Regular
    font-size: 18px
    color: #f9b600
    letter-spacing: 1.83px
    background:#075a72
    background: rgba(#075a72,1)
    content: attr(data-title)
    padding: 11px 22px
    white-space: nowrap
    display: block
    position: absolute
    left: 0
    bottom: 18px
    z-index: 8

.ifa-tooltip:before
    border: solid
    border-color: rgba(#075a72,1) transparent
    border-width: 15px 15px 0 15px
    content: ""
    display: block
    position: absolute
    left: 50%
    bottom: 3px
    margin-left: -15px
    z-index: 9

.button
    display: inline-block
    position: relative
    text-decoration: none
    min-width: 167px
    font-family: TimesIFA-Regular
    font-size: 18px
    color: #f9b600
    letter-spacing: 1.83px
    background:#075a72
    background: rgba(#075a72,1)
    padding: 11px 30px
    z-index: 8
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10), 6px 8px 9px 0 rgba(0,0,0,0.15)

    +mobile()
        min-width: 240px

    &.phone
        background: #075a72 url('/img/phone.svg') no-repeat 20px center
        background-size: 19px
        padding-left: 50px

    &:hover
        color: #fff
        box-shadow: 0 6px 6px 0 rgba(0,0,0,0.20), 6px 8px 9px 0 rgba(0,0,0,0.25)

.separator
    width: 100%
    height: 2px
    background: rgba(#075a72, 0.3)
    border-bottom: solid 1px #fff
    margin: 30px 0 50px 0

.action-button
    border-radius: 50%
    background: #075a72
    box-shadow: 2px 4px 6px 0 rgba(0,0,0,0.23)
    transition: background 0.25s ease-out
    transition: box-shadow 0.25s ease-out
    
    &:hover
        background: darken(#075a72, 10%)
        box-shadow: 0 6px 6px 0 rgba(0,0,0,0.16), 6px 6px 9px 0 rgba(0,0,0,0.18)

.bubble-with-action-button
    position: relative
    
    .button-beside-bubble
        position: absolute
        top: 0
        margin-left: 340px
        cursor: default

.responsive-video
    height: 0px
    margin: 30px 0
    overflow: hidden
    padding-top: 56.25%
    position: relative
    width: 100%

    iframe
        height: 100%
        left: 0px
        position: absolute
        z-index: 998
        top: 0px
        width: 100%


// Contact form
.form-horizontal
    margin-top: 40px

    .control-label
        text-align: left

    textarea
        resize: none
        font-family: TimesIFA-Regular

    .control-label
        font-weight: lighter
        font-family: TimesIFA-Regular

    .right-arrow-bordered
        font-family: TimesIFA-Regular
        font-size: 18px
        color: #f9b600
        letter-spacing: 1.52px
        background:#075a72
        border: none
        box-shadow: 6px 8px 9px 0 rgba(0,0,0,0.15)
        padding-left: 65px
        padding-right: 65px
        margin-bottom: 114px
        
        &:hover
            color: #fff
            box-shadow: 0 6px 6px 0 rgba(0,0,0,0.20), 6px 8px 9px 0 rgba(0,0,0,0.25)

    .form-control
        border-radius: 0
        -webkit-box-shadow: none
        box-shadow: none
        border-color: #fff
        font-weight: normal
        font-family: TimesIFA-Regular
        
        &:active, &:focus
            border-color: #3f738d


// Alert messages
.alert
    border-radius: 0!important


// Toggle content
.toggle
    cursor: pointer

.content-hidden
    display: none


// Image zoom
.zoomable
    -webkit-transition: all .2s ease-in-out
    -moz-transition: all .2s ease-in-out
    -o-transition: all .2s ease-in-out
    -ms-transition: all .2s ease-in-out

.transition
    -webkit-transform: scale(1.1)
    -moz-transform: scale(1.1)
    -o-transform: scale(1.1)
    transform: scale(1.1)


// Helpers
.align-center
    text-align: center

.align-center-mobile
    +mobile()
        text-align: center!important

.margin-bottom-30-mobile
    +mobile()
        margin-bottom: 30px!important

.align-right
    text-align: right

.align-left
    text-align: left

.margin-top-20
    margin-top: 20px

.margin-bottom-20
    margin-bottom: 20px

.margin-top-30
    margin-top: 30px

.margin-bottom-40
    margin-bottom: 40px

.margin-bottom-60
    margin-bottom: 60px

.margin-bottom-80
    margin-bottom: 80px

.margin-top-30
    margin-top: 30px

.min-height-70
    min-height: 70px
    
    +mobile()
        min-height: auto

.no-margin-bottom
    margin-bottom: 0

.spacer-30
    height: 1px
    width: 100%
    margin-top: 30px

.no-padding-left
    padding-left: 0
    
    +mobile()
        padding-left: 15px

.no-padding-right
    padding-right: 0
    
    +mobile()
        padding-right: 15px

.padding-left-mobile-0
    +mobile()
        padding-left: 0 !important

.padding-right-mobile-0
    +mobile()
        padding-right: 0 !important

.right-arrow-bordered
    border: 1px solid #8cabbb
    -webkit-background-clip: padding-box
    background-clip: padding-box
    position: relative
    background: #075a72
    height: 47px
    float: right
    webkit-transition: all 0.3s ease-in-out
    -moz-transition: all 0.3s ease-in-out
    -o-transition: all 0.3s ease-in-out
    transition: all 0.3s ease-in-out
    
    a
        display: inline-block
        line-height: 44px
        padding-left: 17px
        padding-right: 17px
        font-family: TimesIFA-Regular
        
        &:hover
            color: #f9b600

.padding-desktop-top-60
    padding-top: 60px !important

    +mobile()
        padding-top: 30px !important

    +tablet()
        padding-top: 30px !important

.with-100
    width: 100%
    vertical-align: top

.clearfix
    clear: both

.show-mobile
    display: none !important

    +mobile()
        display: block !important

.hide-mobile
    +mobile()
        display: none !important

.hide-tablet
    +tablet()
        display: none !important